/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import "./CreateNewMaster.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
// import { toast } from "react-toastify";
import { toast } from "react-hot-toast";

import { passwordGenerator } from "../../utils/passwordGenerator";
import useCreateAccount from "../../hooks/useCreateAccount";
import useUpdateLimit from "../../hooks/useUpdateLimit";
import useGetAccountStatus from "../../hooks/useGetAccountStatus";

export default function CreateNewMaster() {
  const accountType = localStorage.getItem("account");
  const uuid = localStorage.getItem("UUID");
  const navigate = useNavigate();

  const userPassword = localStorage.getItem("password");
  const { mutate: accountStatus } = useGetAccountStatus();

  const location = useLocation();
  let parentData = [];
  if (location.state) {
    parentData = location.state;
  } else {
    parentData = JSON.parse(localStorage.getItem("DATA"));
  }
  const { mutate: createAccount } = useCreateAccount();
  const { mutate: updateLimit } = useUpdateLimit();
  const [masterName, setMasterName] = useState("");
  const [masterNumber, setMasterNumber] = useState("");
  const [masterPassword, setMasterPassword] = useState("");
  const [masterReference, setMasterReference] = useState("");
  const [masterLimit, setMasterLimit] = useState(0);
  const [masterShare, setMasterShare] = useState(0);
  const [masterCasinoShare, setMasterCasinoShare] = useState(0);
  const [masterMobileShare, setMasterMobileShare] = useState(0);
  const [masterCommission, setMasterCommission] = useState(
    "commission_2be03622ef6c"
  );
  const [masterMatchCommission, setMasterMatchCommission] = useState(0);
  const [masterSessionCommission, setMasterSessionCommission] = useState(0);
  const [masterCassinoCommission, setMasterCassinoCommission] = useState(0);

  useEffect(() => {
    const payload = {
      user_id: uuid,
      acc_type: accountType,
    };

    accountStatus(payload, {
      onSuccess: (response) => {
        let userData = response.data.data;

        if (
          userData.status === false ||
          userData.status === "false" ||
          userData.status === "0" ||
          userData.status === 0
        ) {
          localStorage.clear();
          sessionStorage.clear();
        } else if (userData.password !== userPassword) {
          localStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setMasterPassword(passwordGenerator());
  }, []);

  const generateNewPassword = () => {
    setMasterPassword(passwordGenerator());
  };

  function handleSubmit(e) {
    e.preventDefault();

    const payload = {
      acc_type: "master",
      name: masterName,
      reference: masterReference,
      password: String(masterPassword),
      parent: parentData?.id,
      parentCode: parentData?.code,
      mobile: masterNumber,
      limit: masterLimit,
      share: masterShare,
      cassino_share: masterCasinoShare,
      mobile_share: masterMobileShare,
      commission: masterCommission,
      match_commission: masterMatchCommission,
      session_commission: masterSessionCommission,
      cassino_commission: masterCassinoCommission,
    };

    toast.promise(
      new Promise((resolve, reject) => {
        createAccount(payload, {
          onSuccess: (data) => {
            console.log(data);
            const masterLimitPayload = {
              user_id: data.data.data.id,
              acc_type: "master",
              limit: masterLimit,
              coins: masterLimit,
              types: "credit",
              description: "Master Limit Update",
            };
            const parentLimitPayload = {
              user_id: parentData?.id,
              acc_type: "sub_admin",
              limit: parentData?.limit - masterLimit,
              coins: masterLimit,
              types: "debit",
              description: "Sub Admin Limit Update",
            };
            updateLimit(parentLimitPayload, {
              onSuccess: () => {
                updateLimit(masterLimitPayload, {
                  onSuccess: () => {
                    resolve(); // Trigger the success toast
                    navigate("/masters");
                  },
                  onError: (error) => {
                    console.error(error);
                    reject(error); // Trigger the error toast
                  },
                });
              },
              onError: (error) => {
                console.error(error);
                reject(error); // Trigger the error toast
              },
            });
          },
          onError: (error) => {
            console.error(error);
            reject(error); // Trigger the error toast
          },
        });
      }),
      {
        loading: "Creating master account...",
        success: <b>Master created successfully!</b>,
        error: <b>Something went wrong! Please try again later.</b>,
      }
    );
  }

  const [isOpen, setIsOpen] = useState(true);
  const [isFirstAccordionOpen, setIsFirstAccordionOpen] = useState(true);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const toggleFirstAccordion = () => {
    setIsFirstAccordionOpen(!isFirstAccordionOpen);
  };

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Create New Master </h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/masters">Master</Link>
                </li>
                <li className="breadcrumb-item active">
                  <a>Create Master</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <form onSubmit={handleSubmit}>
            <div className="row mt-4 mb-4">
              {/* General Form */}
              <div className="col-md-6">
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className={`accordion-button ${
                          isOpen ? "" : "collapsed"
                        }`}
                        id="create-button-1"
                        type="button"
                        onClick={toggleAccordion}
                        aria-expanded={isOpen}
                        aria-controls="collapseOne"
                      >
                        General
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className={`accordion-collapse collapse ${
                        isOpen ? "show" : ""
                      }`}
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <div className="form-group mb-2">
                          <label className="form-label" htmlFor="masterCode">
                            Code
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="masterCode"
                            name="masterCode"
                            value="Auto generated"
                            disabled
                          />
                        </div>
                        <div className="form-group mb-2">
                          <label className="form-label">Name</label>
                          <input
                            type="text"
                            id="masterName"
                            name="masterName"
                            className="form-control"
                            placeholder="Enter Name"
                            required
                            onChange={(e) => {
                              setMasterName(e.target.value);
                            }}
                          />
                        </div>
                        <div className="form-group mb-2">
                          <label className="form-label" htmlFor="masterNumber">
                            Contact Number
                          </label>
                          <input
                            type="text"
                            id="masterNumber"
                            name="masterNumber"
                            className="form-control"
                            placeholder="Enter Mobile Number"
                            minLength={0}
                            maxLength={10}
                            required
                            onChange={(e) => {
                              setMasterNumber(e.target.value);
                            }}
                          />
                        </div>
                        <div className="form-group mb-2">
                          <label className="form-label">Reference</label>
                          <input
                            type="text"
                            id="masterReference"
                            name="masterReference"
                            className="form-control"
                            placeholder="Enter Reference"
                            required
                            onChange={(e) => {
                              setMasterReference(e.target.value);
                            }}
                          />
                        </div>
                        <div className="form-group mb-2">
                          <label
                            className="form-label"
                            htmlFor="masterPassword"
                          >
                            Password
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              id="masterPassword"
                              name="masterPassword"
                              className="form-control"
                              required
                              value={masterPassword}
                              onChange={(e) => {
                                setMasterPassword(e.target.value);
                              }}
                            />
                            <span
                              class="input-group-text btn btn-primary"
                              onClick={generateNewPassword}
                            >
                              Generate Password!
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Share and Commission Form */}
              <div className="col-md-6">
                <div className="accordion" id="accordionExample2">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className={`accordion-button ${
                          isFirstAccordionOpen ? "" : "collapsed"
                        }`}
                        id="create-button-2"
                        type="button"
                        onClick={toggleFirstAccordion}
                        aria-expanded={isFirstAccordionOpen}
                        aria-controls="collapseTwo"
                      >
                        Share and Commission
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className={`accordion-collapse collapse ${
                        isFirstAccordionOpen ? "show" : ""
                      }`}
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample2"
                    >
                      <div class="accordion-body">
                        <div className="row">
                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">Master Limit</label>
                            <input
                              type="number"
                              id="masterLimit"
                              name="masterLimit"
                              className="form-control"
                              placeholder="0.00"
                              min="0"
                              max={parentData?.limit}
                              required
                              onChange={(e) => {
                                setMasterLimit(e.target.value);
                              }}
                            />
                          </div>
                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">My Limit</label>
                            <input
                              type="number"
                              className="form-control"
                              value={parentData?.limit}
                              disabled
                            />
                          </div>

                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">Master Share</label>
                            <input
                              type="number"
                              id="masterShare"
                              name="masterShare"
                              className="form-control"
                              placeholder="0.00"
                              min="0"
                              max={parentData?.share}
                              step="0.01"
                              required
                              onChange={(e) => {
                                setMasterShare(e.target.value);
                              }}
                            />
                          </div>
                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">My Share</label>
                            <input
                              type="number"
                              className="form-control"
                              value={parentData?.share}
                              disabled
                            />
                          </div>

                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              Master Casino Share
                            </label>
                            <input
                              type="number"
                              id="masterCasinoShare"
                              name="masterCasinoShare"
                              className="form-control"
                              placeholder="0.00"
                              min="0"
                              max={parentData?.cassino_share}
                              step="0.01"
                              required
                              onChange={(e) => {
                                setMasterCasinoShare(e.target.value);
                              }}
                            />
                          </div>
                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              My Casino Share
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              value={parentData?.cassino_share}
                              disabled
                            />
                          </div>

                          <div className="form-group mb-2 col-md-12">
                            <label className="form-label">Casino Status</label>
                            <select className="form-control">
                              <option value="off">OFF</option>
                              <option value="on">ON</option>
                            </select>
                          </div>

                          <div className="form-group mb-2 col-md-12">
                            <label className="form-label">
                              Commission Type
                            </label>
                            <select
                              className="form-control"
                              id="masterCommission"
                              name="masterCommission"
                              required
                              onChange={(e) => {
                                const value = e.target.value;
                                setMasterCommission(value);

                                // Automatically set match, session, and cassino commissions to 0 if 'No Com' is selected
                                if (value === "commission_6e444f0e316c") {
                                  setMasterMatchCommission(0);
                                  setMasterSessionCommission(0);
                                  setMasterCassinoCommission(0);
                                }
                              }}
                            >
                              <option value="commission_2be03622ef6c">
                                Bet By Bet
                              </option>
                              <option value="commission_6e444f0e316c">
                                No Com
                              </option>
                            </select>
                          </div>

                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              Master Match Commission
                            </label>
                            <input
                              type="number"
                              id="masterMatchCommission"
                              name="masterMatchCommission"
                              className="form-control"
                              placeholder="0.00"
                              min="0"
                              max={parentData?.match_commission}
                              value={masterMatchCommission} // Bind value to state
                              required
                              onChange={(e) =>
                                setMasterMatchCommission(e.target.value)
                              }
                              disabled={
                                masterCommission === "commission_6e444f0e316c"
                              } // Disable if 'No Com' selected
                            />
                          </div>

                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              My Match Commission
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              value={parentData?.match_commission}
                              disabled
                            />
                          </div>

                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              Master Session Commission
                            </label>
                            <input
                              type="number"
                              id="masterSessionCommission"
                              name="masterSessionCommission"
                              className="form-control"
                              placeholder="0.00"
                              min="0"
                              max={parentData?.session_commission}
                              value={masterSessionCommission} // Bind value to state
                              required
                              onChange={(e) =>
                                setMasterSessionCommission(e.target.value)
                              }
                              disabled={
                                masterCommission === "commission_6e444f0e316c"
                              } // Disable if 'No Com' selected
                            />
                          </div>

                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              My Session Commission
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              value={parentData?.session_commission}
                              disabled
                            />
                          </div>

                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              Master Cassino Commission
                            </label>
                            <input
                              type="number"
                              id="masterCassinoCommission"
                              name="masterCassinoCommission"
                              className="form-control"
                              placeholder="0.00"
                              min="0"
                              max={parentData?.cassino_commission}
                              value={masterCassinoCommission} // Bind value to state
                              required
                              onChange={(e) =>
                                setMasterCassinoCommission(e.target.value)
                              }
                              disabled={
                                masterCommission === "commission_6e444f0e316c"
                              } // Disable if 'No Com' selected
                            />
                          </div>

                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              My Cassino Commission
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              value={parentData?.cassino_commission}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Action Buttons */}
              <div className="d-flex w-100 mt-2 col-12 justify-content-between">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/masters");
                  }}
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-success">
                  Create New Master
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
}
