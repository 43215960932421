/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import useGetMyLedger from "../../hooks/useGetMyLedger";
import { dateTimeFormatter } from "../../utils/dateFormatter";
// import { toast } from "react-toastify";
import { toast } from "react-hot-toast";

import useGetAccountStatus from "../../hooks/useGetAccountStatus";

export default function MyLedger() {
  const accountType = localStorage.getItem("account");
  const uuid = localStorage.getItem("UUID");
  const userPassword = localStorage.getItem("password");

  const navigate = useNavigate();

  const { mutate: accountStatus } = useGetAccountStatus();

  const { mutate: getMyLedger } = useGetMyLedger();
  const [data, setData] = useState([]);
  const [totalDebit, setTotalDebit] = useState(0);
  const [totalCredit, setTotalCredit] = useState(0);
  const [totalBalance, setTotalBalance] = useState(0);

  useEffect(() => {
    const payload = {
      user_id: uuid,
      acc_type: accountType,
    };

    accountStatus(payload, {
      onSuccess: (response) => {
        let userData = response.data.data;

        if (
          userData.status === false ||
          userData.status === "false" ||
          userData.status === "0" ||
          userData.status === 0
        ) {
          localStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else if (userData.password !== userPassword) {
          localStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const payload = {
      user_id: localStorage.getItem("UUID"),
      logged_acc_type: localStorage.getItem("account"),
    };

    getMyLedger(payload, {
      onSuccess: (response) => {
        //console.log(response);
        // setData(response.data);

        let updatedData = [];

        response.data.forEach((element) => {
          if (element.games != null) {
            let accTypeCom = 0;

            // Use dynamic property access based on acc_type
            if (element.games[0]?.name !== "Cash Payment") {
              if (accountType === "agent") {
                accTypeCom = element.agent_t_com;
              } else if (accountType === "super_agent") {
                accTypeCom = element.super_agent_t_com;
              } else if (accountType === "master") {
                accTypeCom = element.master_t_com;
              } else if (accountType === "sub_admin") {
                accTypeCom = element.sub_admin_t_com;
              } else if (accountType === "super_admin") {
                accTypeCom = element.super_admin_t_com;
              }
            }

            const existingIndex = updatedData.findIndex(
              (el) => el.match_id === element.match_id
            );

            if (existingIndex !== -1) {
              if (element.total_final !== 0) {
                updatedData[existingIndex].total_final =
                  updatedData[existingIndex].total_final + element.total_final;
              } else {
                updatedData[existingIndex].total_final =
                  updatedData[existingIndex].total_final + 0;
              }
            } else {
              if (element.super_admin_final !== 0) {
                updatedData.push({
                  ...element,
                  balance: element.total_final,
                  c_com: accTypeCom,
                });
              } else {
                updatedData.push({
                  ...element,
                  balance: 0,
                  c_com: 0,
                });
              }
            }
          } else {
            updatedData.push({
              ...element,
              balance: 0,
              c_com: 0,
            });
          }
        });

        updatedData?.sort((a, b) => {
          return (
            new Date(b.games[0]?.createdAt || b.createdAt) -
            new Date(a.games[0]?.createdAt || a.createdAt)
          );
        });

        updatedData.reverse();

        for (let index = 0; index < updatedData.length; index++) {
          if (index === 0) {
            if (updatedData[index].total_final === 0) {
              updatedData[index].balance = 0;
            } else {
              updatedData[index].balance = Math.round(
                updatedData[index].total_final
              );
            }
          } else {
            if (updatedData[index].total_final === 0) {
              updatedData[index].balance = Math.round(
                updatedData[index - 1].balance
              );
            } else {
              updatedData[index].balance = Math.round(
                updatedData[index - 1].balance + updatedData[index].total_final
              );
            }
          }
        }

        updatedData.reverse();

        let totalAmount = 0;
        let debitAmount = 0;
        let creditAmount = 0;

        updatedData.forEach((element) => {
          if (element.total_final > 0) {
            debitAmount += element.total_final;
          } else if (element.total_final < 0) {
            creditAmount += element.total_final;
          } else {
            debitAmount += 0;
            creditAmount += 0;
          }
        }, 0);

        totalAmount = creditAmount + debitAmount;

        setTotalDebit(Math.round(debitAmount));
        setTotalCredit(Math.round(creditAmount));
        setTotalBalance(Math.round(totalAmount));

        setData(updatedData);
      },
      onError: (error) => {
        console.log("error", error);
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="content-wrapper">
      <section className="content">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>My Ledger</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item active">
                  <a>My Ledger</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="card p-2">
            <div className="table-responsive">
              <table
                id="datatable"
                className="table table-bordered table-striped"
              >
                <thead>
                  <tr>
                    <th style={{ minWidth: "50px", height: "57px" }}>#</th>
                    <th style={{ minWidth: "80px", height: "57px" }}>Date</th>
                    <th style={{ minWidth: "123px", height: "57px" }}>
                      Collection Name
                    </th>
                    <th style={{ minWidth: "58px", height: "57px" }}>Debit</th>
                    <th style={{ minWidth: "59px", height: "57px" }}>Credit</th>
                    <th style={{ minWidth: "69px", height: "57px" }}>
                      Balance
                    </th>
                    <th style={{ minWidth: "110px", height: "57px" }}>
                      Payment Type
                    </th>
                    <th style={{ minWidth: "363px", height: "57px" }}>
                      Remark
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.length !== 0 ? (
                    <>
                      <tr className="fw-bold">
                        <td
                          style={{ height: "40px" }}
                          colSpan="3"
                          className={`text-center text-lg fw-bold ${
                            totalBalance > 0 ? "text-danger" : "text-primary"
                          }`}
                        >
                          Total Amount
                        </td>

                        <td className="text-black text-lg fw-bold">
                          {totalCredit}
                        </td>
                        <td className="text-black text-lg fw-bold">
                          {totalDebit}
                        </td>
                        <td
                          style={{ height: "55px" }}
                          className={
                            totalBalance > 0
                              ? "text-danger fw-bold text-lg"
                              : "text-primary fw-bold text-lg"
                          }
                        >
                          {totalBalance}
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                      {data.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td style={{ height: "60px" }}>{index + 1}</td>
                            <td>
                              {item?.games
                                ? dateTimeFormatter(item?.games[0]?.createdAt)
                                : dateTimeFormatter(item?.createdAt)}
                            </td>
                            <td>
                              {item?.games ? item?.games[0]?.name : "Cash Pay"}
                            </td>

                            <td className="text-black">
                              {item?.total_final < 0 && item.total_final !== 0
                                ? Math.round(item?.total_final)
                                : 0}
                            </td>
                            <td className="text-black">
                              {item?.total_final > 0 && item.total_final !== 0
                                ? Math.round(item?.total_final)
                                : 0}
                            </td>
                            <td
                              className={
                                item?.balance > 0 ? "text-black" : "text-black"
                              }
                            >
                              {item?.balance}
                            </td>
                            <td>
                              {item.games[0].name === "Cash Payment"
                                ? item.total_final < 0
                                  ? "LENA"
                                  : "DENA"
                                : ""}
                            </td>
                            <td>
                              {item?.games
                                ? item?.games[0]?.name
                                : item?.remarks}
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  ) : (
                    <tr>
                      <td colSpan="8" className="text-center">
                        No User Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
