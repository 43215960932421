import React, { useState, useEffect } from "react";
import "./cashtransaction.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import PageHeader from "../../Components/PageHeader";
import Card from "../../Components/Card";
import useGetAccountStatus from "../../hooks/useGetAccountStatus";
import useGetTotalCommission from "../../hooks/Ledger/useGetTotalCommission";
import { dateFormatter } from "../../utils/dateFormatter";
import Select from "react-select";

export default function ClientTotalComission(props) {
  const accountType = localStorage.getItem("account");
  const uuid = localStorage.getItem("UUID");
  const userPassword = localStorage.getItem("password");
  const navigate = useNavigate();

  const { mutate: accountStatus } = useGetAccountStatus();
  const { mutate: getTotalCommission } = useGetTotalCommission();

  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [user, setUser] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  function changeUser(value) {
    if (value === "all" || value === "") {
      setData(allData);
    } else {
      const filteredData = allData.filter(
        (item) => item.client_user_id === value
      );
      setData(filteredData);
    }
  }

  function handleSubmit(e) {
    e.preventDefault();

    if (user === "" || fromDate === "" || toDate === "") {
      setData(allData);
    } else {
      const filteredData = allData.filter((item) => {
        return (
          item.client_user_id === user &&
          new Date(item.updatedAt) >= new Date(fromDate) &&
          new Date(item.updatedAt) <= new Date(toDate)
        );
      });

      setData(filteredData);
    }
  }

  useEffect(() => {
    const payload = {
      user_id: uuid,
      acc_type: accountType,
    };

    accountStatus(payload, {
      onSuccess: (response) => {
        let userData = response.data.data;

        if (
          userData.status === false ||
          userData.status === "false" ||
          userData.status === "0" ||
          userData.status === 0
        ) {
          localStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else if (userData.password !== userPassword) {
          localStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const payload = {
      user_id: uuid,
      logged_acc_type: accountType,
      acc_type: "user",
    };

    getTotalCommission(payload, {
      onSuccess: (response) => {
        let userData = response.data.won;

        let users = [];

        userData.forEach((el) => {
          if (!users.some((ele) => ele.id === el.client_user_id)) {
            users.push({
              id: el.client_user_id,
              name: `${el.user_accounts[0].name}`,
              code: `${el.user_accounts[0].code}`,
            });
          }
        });

        userData.sort((a, b) => {
          return new Date(b.updatedAt) - new Date(a.updatedAt);
        });

        setData(userData);
        setAllData(userData);
        setUsersList(users);

        console.log(userData);
        console.log(users);
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="content-wrapper">
      <PageHeader
        title="Commission Len Den
"
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <Card header="Ledger">
              <div className="row">
                <div className="form-group col-md-3 mb-3">
                  <label className="form-label">Users</label>
                  <Select
                    options={[
                      { value: "", label: "Select" },
                      { value: "all", label: "All" },
                      ...usersList.map((user) => ({
                        value: user.id,
                        label: `${user.code} - ${user.name}`,
                      })),
                    ]}
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={""}
                    isDisabled={false}
                    isLoading={usersList.length === 0 ? true : false}
                    isSearchable={true}
                    isRtl={false}
                    name="selectedUser"
                    onChange={(e) => {
                      const selected = e?.value;
                      setUser(selected);
                      changeUser(selected);
                    }}
                  />
                </div>
                <div className="form-group col-md-3 mb-3">
                  <label className="form-label">From Date</label>
                  <input
                    type="date"
                    name="from"
                    className="form-control"
                    onChange={(e) => {
                      setFromDate(e.target.value);
                    }}
                  />
                </div>
                <div className="form-group col-md-3 mb-3">
                  <label className="form-label">To Date</label>
                  <input
                    type="date"
                    name="to"
                    className="form-control"
                    onChange={(e) => {
                      setToDate(e.target.value);
                    }}
                  />
                </div>
                <div className="form-group col-md-3 mb-3 align-self-end">
                  <button
                    className="btn btn-primary btn-block"
                    onClick={(e) => {
                      e.preventDefault();
                      handleSubmit(e);
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
              <div className="table-responsive pl-4 pr-4">
                <table
                  id="datatable"
                  className="table table-bordered table-striped"
                >
                  <thead>
                    <tr>
                      <th>#</th>
                      <th style={{ fontWeight: "700" }}>
                        DATE <span id="sorted-icon">↑↓</span>
                      </th>
                      <th style={{ fontWeight: "700" }}>
                        CODE <span id="sorted-icon">↑↓</span>
                      </th>
                      <th style={{ fontWeight: "700" }}>
                        M Comm. <span id="sorted-icon">↑↓</span>
                      </th>
                      <th style={{ fontWeight: "700" }}>
                        S Comm. <span id="sorted-icon">↑↓</span>
                      </th>
                      <th style={{ fontWeight: "700" }}>
                        Total Comm <span id="sorted-icon">↑↓</span>
                      </th>
                      <th style={{ fontWeight: "700" }}>
                        M Comm. <span id="sorted-icon">↑↓</span>
                      </th>
                      <th style={{ fontWeight: "700" }}>
                        S Comm. <span id="sorted-icon">↑↓</span>
                      </th>
                      <th style={{ fontWeight: "700" }}>
                        Total Comm <span id="sorted-icon">↑↓</span>
                      </th>
                      <th>Comm Bacha</th>
                    </tr>
                    <tr>
                      <th>#</th>

                      <th></th>

                      <th>CLIENT</th>

                      <th
                        colSpan={3}
                        style={{ color: "green", fontSize: "16px" }}
                      >
                        UPER SE MILA
                      </th>

                      <th
                        colSpan={3}
                        style={{ color: "red", fontSize: "16px" }}
                      >
                        CLIENT KO DIYA
                      </th>
                      <th style={{ color: "blue", fontSize: "16px" }}>
                        TOTAL BACHA{" "}
                        <span id="sorted-icon" style={{ color: "blue" }}>
                          ↑↓
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length !== 0 ? (
                      data.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{dateFormatter(item.updatedAt)}</td>
                          <td>
                            {item.user_accounts[0].code}{" "}
                            {item.user_accounts[0].name}
                          </td>
                          <td>
                            {item.m_amt < 0
                              ? 0
                              : (
                                  item.m_amt *
                                  (item.user_accounts[0].user_agent
                                    .match_commission /
                                    100)
                                ).toFixed(2)}
                          </td>
                          <td>
                            {item.m_amt < 0
                              ? item.c_com
                              : (
                                  item.c_com -
                                  item.m_amt *
                                    (item.user_accounts[0].user_agent
                                      .match_commission /
                                      100)
                                ).toFixed(2)}
                          </td>
                          <td>
                            {item.m_amt < 0
                              ? (0 + item.c_com).toFixed(2)
                              : (
                                  item.m_amt *
                                    (item.user_accounts[0].user_agent
                                      .match_commission /
                                      100) +
                                  (item.c_com -
                                    item.m_amt *
                                      (item.user_accounts[0].user_agent
                                        .match_commission /
                                        100))
                                ).toFixed(2)}
                          </td>
                          <td>
                            {item.m_amt < 0
                              ? 0
                              : (
                                  item.m_amt *
                                  (item.user_accounts[0].match_commission / 100)
                                ).toFixed(2)}
                          </td>
                          <td>
                            {item.m_amt < 0
                              ? item.c_com
                              : (
                                  item.c_com -
                                  item.m_amt *
                                    (item.user_accounts[0].match_commission /
                                      100)
                                ).toFixed(2)}
                          </td>
                          <td>
                            {item.c_com !== null ? item.c_com.toFixed(2) : 0}
                          </td>
                          <td className="fw-bold text-md">
                            {item.m_amt < 0
                              ? (0 + item.c_com - item.c_com).toFixed(2)
                              : (
                                  item.m_amt *
                                    (item.user_accounts[0].user_agent
                                      .match_commission /
                                      100) +
                                  (item.c_com -
                                    item.m_amt *
                                      (item.user_accounts[0].user_agent
                                        .match_commission /
                                        100)) -
                                  item.c_com
                                ).toFixed(2)}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="9" className="text-center">
                          No User Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}
