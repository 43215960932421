/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import "./supermaster.css";
import { Link, useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
import { toast } from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import useGetSuperAdminList from "../../hooks/SuperAdmin/useGetSuperAdminList";
import useUpdateAccountStatus from "../../hooks/useUpdateAccountStatus";
import { useQueryClient } from "react-query";
import useGetAccountStatus from "../../hooks/useGetAccountStatus";

export default function SuperMasterList() {
  const accountType = localStorage.getItem("account");
  const uuid = localStorage.getItem("UUID");
  const userPassword = localStorage.getItem("password");
  const { mutate: accountStatus } = useGetAccountStatus();

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data: superAdminListData } = useGetSuperAdminList();
  const { mutate: updateAccountStatus } = useUpdateAccountStatus();

  const [completeData, setCompleteData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [data, setData] = useState([]);

  useEffect(() => {
    const payload = {
      user_id: uuid,
      acc_type: accountType,
    };

    accountStatus(payload, {
      onSuccess: (response) => {
        let userData = response.data.data;

        if (
          userData.status === false ||
          userData.status === "false" ||
          userData.status === "0" ||
          userData.status === 0
        ) {
          localStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else if (userData.password !== userPassword) {
          localStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const listData = superAdminListData?.data;

    if (listData) {
      listData.sort((a, b) => {
        return +new Date(b.createdAt) - +new Date(a.createdAt);
      });
      setData(listData);
      setCompleteData(listData);
      // console.log(listData);
    }
  }, [superAdminListData]);

  function searchFilter(value) {
    const filteredData = data.filter((item) => {
      return (
        item.code.toLowerCase().includes(value.toLowerCase()) ||
        item.name.toLowerCase().includes(value.toLowerCase())
      );
    });

    setData(filteredData);
  }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const [isFirstPage, setIsFirstPage] = useState(true);
  const [isLastPage, setIsLastPage] = useState(false);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handlePrevious = () => {
    if (currentPage > 1) {
      paginate(currentPage - 1);
      setIsFirstPage(currentPage - 1 <= 3);

      setIsLastPage(false);
    }
  };

  const handleNext = () => {
    if (currentPage < Math.ceil(data.length / itemsPerPage)) {
      paginate(currentPage + 1);
      setIsFirstPage(false);
      setIsLastPage(
        currentPage + 1 >= Math.ceil(data.length / itemsPerPage) - 2
      );
    }
  };

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleUserSelect = (e, userId) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      setSelectedUsers([...selectedUsers, userId]);
    } else {
      setSelectedUsers(selectedUsers.filter((id) => id !== userId));
    }
  };

  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      setSelectedUsers(data.map((item) => item.id));
    } else {
      setSelectedUsers([]);
    }

    setSelectAll(isChecked);
  };

  const superAdminAllActive = () => {
    const acc_type = "super_admin";
    const status = true;

    selectedUsers.forEach((id) => {
      const payload = { acc_type, id, status };

      updateAccountStatus(payload, {
        onSuccess: () => {
          setSelectedUsers([]);
          toast.success("Clients activated successfully");
          queryClient.invalidateQueries("get-super-admin-list");
        },
        onError: () => {
          toast.error("Failed to activate client");
        },
      });
    });
  };

  const superAdminAllDeactive = () => {
    const acc_type = "super_admin";
    const status = false;

    selectedUsers.forEach((id) => {
      const payload = { acc_type, id, status };

      updateAccountStatus(payload, {
        onSuccess: () => {
          setSelectedUsers([]);
          toast.success("Clients deactivated successfully");
          queryClient.invalidateQueries("get-super-admin-list");
        },
        onError: () => {
          toast.error("Failed to deactivate client");
        },
      });
    });
  };

  const showResponsiveCols = (action, item) => {
    let existingElement = document.getElementById(`row${item.code}`);

    let showBtn = document.getElementById(`showBtn${item.code}`);
    let hideBtn = document.getElementById(`hideBtn${item.code}`);

    if (existingElement) {
      if (action === "show") {
        let tr = document.createElement("tr");
        tr.setAttribute("id", `showRow${item.code}`);

        let tdTitle = document.createElement("td");
        tdTitle.setAttribute("colspan", "2");
        tdTitle.style.borderRight = "none";

        let tdValue = document.createElement("td");
        tdValue.setAttribute("colspan", "10");
        tdValue.style.borderLeft = "none";

        let title1 = document.createElement("div");
        title1.classList.add("dataTitle");
        title1.textContent = "Status ";

        let value1 = document.createElement("div");
        value1.classList.add("dataValue");
        value1.textContent = "";

        let title11 = document.createElement("div");
        title11.classList.add("dataTitle");
        let statusSpan = document.createElement("span");
        statusSpan.textContent = item.status ? "Active" : "Inactive";
        if (item.status) {
          statusSpan.classList.add("active_bg");
        } else {
          statusSpan.classList.add("inactive_bg");
        }
        title11.appendChild(statusSpan);

        let value11 = document.createElement("div");
        value1.classList.add("dataValue");
        value1.textContent = "";

        let title2 = document.createElement("div");
        title2.classList.add("dataTitle");
        title2.textContent = "Limit: ";

        let value2 = document.createElement("div");
        value2.classList.add("dataValue");
        value2.textContent = Math.round(item.limit);

        let title3 = document.createElement("div");
        title3.classList.add("dataTitle");
        title3.textContent = "S. Com: ";

        let value3 = document.createElement("div");
        value3.classList.add("dataValue");
        value3.textContent = item.session_commission;

        let title4 = document.createElement("div");
        title4.classList.add("dataTitle");
        title4.textContent = "M. Com: ";

        let value4 = document.createElement("div");
        value4.classList.add("dataValue");
        value4.textContent = item.match_commission;

        let title5 = document.createElement("div");
        title5.classList.add("dataTitle");
        title5.textContent = "Share: ";

        let value5 = document.createElement("div");
        value5.classList.add("dataValue");
        value5.textContent = item.share;

        let title6 = document.createElement("div");
        title6.classList.add("dataTitle");
        title6.textContent = "Contact: ";

        let value6 = document.createElement("div");
        value6.classList.add("dataValue");
        value6.textContent = item.mobile;

        let title7 = document.createElement("div");
        title7.classList.add("dataTitle");
        title7.textContent = "Password: ";

        let value7 = document.createElement("div");
        value7.classList.add("dataValue");
        value7.textContent = item.password;

        let title8 = document.createElement("div");
        title8.classList.add("dataTitle");
        title8.textContent = "Name: ";

        let value8 = document.createElement("div");
        value8.classList.add("dataValue");
        value8.textContent = item.name;

        let title9 = document.createElement("div");
        title9.classList.add("dataTitle");
        title9.textContent = "CODE: ";

        let value9 = document.createElement("div");
        value9.classList.add("dataValue");
        value9.textContent = item.code;

        let screenWidth = window.innerWidth;
        if (screenWidth < 992) {
          tdTitle.appendChild(title1);
          tdValue.appendChild(value1);
        }

        if (screenWidth < 992) {
          tdTitle.appendChild(title11);
          tdValue.appendChild(value11);
        }

        if (screenWidth < 912) {
          tdTitle.insertBefore(title2, tdTitle.firstChild);
          tdValue.insertBefore(value2, tdValue.firstChild);
        }

        if (screenWidth < 832) {
          tdTitle.insertBefore(title3, tdTitle.firstChild);
          tdValue.insertBefore(value3, tdValue.firstChild);
        }

        if (screenWidth < 752) {
          tdTitle.insertBefore(title4, tdTitle.firstChild);
          tdValue.insertBefore(value4, tdValue.firstChild);
        }

        if (screenWidth < 672) {
          tdTitle.insertBefore(title5, tdTitle.firstChild);
          tdValue.insertBefore(value5, tdValue.firstChild);
        }

        if (screenWidth < 592) {
          tdTitle.insertBefore(title6, tdTitle.firstChild);
          tdValue.insertBefore(value6, tdValue.firstChild);
        }

        if (screenWidth < 512) {
          tdTitle.insertBefore(title7, tdTitle.firstChild);
          tdValue.insertBefore(value7, tdValue.firstChild);
        }

        if (screenWidth < 432) {
          tdTitle.insertBefore(title8, tdTitle.firstChild);
          tdValue.insertBefore(value8, tdValue.firstChild);
        }

        if (screenWidth < 352) {
          tdTitle.insertBefore(title9, tdTitle.firstChild);
          tdValue.insertBefore(value9, tdValue.firstChild);
        }

        tr.appendChild(tdTitle);
        tr.appendChild(tdValue);

        if (existingElement.parentNode != null) {
          existingElement.parentNode.insertBefore(
            tr,
            existingElement.nextSibling
          );
        }

        showBtn.classList.toggle("showButton");
        hideBtn.classList.toggle("showButton");
      } else {
        let row = document.getElementById(`showRow${item.code}`);
        if (row) {
          row.remove();
        }

        showBtn.classList.toggle("showButton");
        hideBtn.classList.toggle("showButton");
      }
    }
  };

  const createNewSuperMaster = () => {
    navigate("/supermaster/create");
  };

  return (
    <div className="content-wrapper">
      <section className="content">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Super Admin</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item active">
                  <a>Super Admin</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-primary mr-1 mt-2"
                      onClick={createNewSuperMaster}
                      style={{ borderRadius: "4px" }}
                    >
                      New{" "}
                      <i className="fa fa-plus-circle">
                        <FontAwesomeIcon icon={faPlusCircle} />
                      </i>
                    </button>

                    <button
                      className={`btn button-check btn-success mr-1 mt-2 ${
                        selectedUsers.length === 0 ? "disabled" : ""
                      }`}
                      id="allActive"
                      onClick={superAdminAllActive}
                      style={{ borderRadius: "4px" }}
                    >
                      All Active
                    </button>
                    <button
                      className={`btn button-check btn-danger mr-1 mt-2 ${
                        selectedUsers.length === 0 ? "disabled" : ""
                      }`}
                      id="allInActive"
                      onClick={superAdminAllDeactive}
                      style={{ borderRadius: "4px" }}
                    >
                      All DeActivate{" "}
                      <i className="fa fa-ban">
                        <FontAwesomeIcon icon={faBan} />
                      </i>
                    </button>

                    <Link
                      to="/supermaster/limitUpdate"
                      className="btn btn-primary mr-1 mt-2"
                      style={{ borderRadius: "4px" }}
                    >
                      Limit Update
                    </Link>
                  </div>
                </div>

                <div className="card-body">
                  <div className="table">
                    <div className="row">
                      <div className="parent-container">
                        <div className="col-6">
                          <div
                            className="label-1 dataTables_length"
                            id="example1_length"
                          >
                            <label>
                              Show
                              <select
                                className="custom-select"
                                onChange={(e) => {
                                  setItemsPerPage(e.target.value);
                                }}
                              >
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                              </select>
                              entries
                            </label>
                          </div>
                        </div>
                        <div className="input-container col-6 d-flex gap-2 justify-content-end">
                          <label htmlFor="search">Search:</label>
                          <input
                            type="text"
                            id="search"
                            className="form-control form-control-sm w-auto ml-0"
                            onChange={(e) => {
                              const value = e.target.value.trim();
                              if (value.length > 0) {
                                searchFilter(value);
                              } else {
                                setData(completeData);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <table
                      id="datatable"
                      className="table table-bordered table-striped dataTable no-footer dtr-inline"
                      role="grid"
                      aria-describedby="example1_info"
                    >
                      <thead>
                        <tr>
                          <th className="thr-control">
                            <input
                              type="checkbox"
                              checked={selectAll}
                              onChange={handleSelectAll}
                              style={{ cursor: "pointer" }}
                            />
                          </th>
                          <th className="toggle-button-size-page">#</th>
                          <th
                            className="mobile-d-10"
                            style={{ textAlign: "start" }}
                          >
                            Code<span id="sorted-icon">↑↓</span>
                          </th>
                          <th
                            className="mobile-d-9"
                            style={{ textAlign: "start" }}
                          >
                            Name<span id="sorted-icon">↑↓</span>
                          </th>
                          <th
                            className="mobile-d-7"
                            style={{ textAlign: "start" }}
                          >
                            Password<span id="sorted-icon">↑↓</span>
                          </th>
                          <th
                            className="mobile-d-6"
                            style={{ textAlign: "start" }}
                          >
                            Contact<span id="sorted-icon">↑↓</span>
                          </th>
                          <th
                            className="mobile-d-5"
                            style={{ textAlign: "start" }}
                          >
                            Share<span id="sorted-icon">↑↓</span>
                          </th>
                          <th
                            className="mobile-d-4"
                            style={{ textAlign: "start" }}
                          >
                            M.Com<span id="sorted-icon">↑↓</span>
                          </th>
                          <th
                            className="mobile-d-3"
                            style={{ textAlign: "start" }}
                          >
                            S.Com<span id="sorted-icon">↑↓</span>
                          </th>
                          <th
                            className="mobile-d-2"
                            style={{ textAlign: "start" }}
                          >
                            Limit<span id="sorted-icon">↑↓</span>
                          </th>
                          <th
                            className="mobile-d-1"
                            style={{ textAlign: "start" }}
                          >
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody style={{ cursor: "pointer" }}>
                        {data && data?.length !== 0 ? (
                          currentItems?.map((item, index) => {
                            return (
                              <tr key={index} id={`row${item.code}`}>
                                <td className="dtr-control">
                                  <input
                                    type="checkbox"
                                    value={item.id}
                                    checked={selectedUsers.includes(item.id)}
                                    onChange={(e) => {
                                      handleUserSelect(e, item.id);
                                    }}
                                  />
                                  <div
                                    className="dynamicControl showButton"
                                    id={`showBtn${item.code}`}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      showResponsiveCols("show", item);
                                    }}
                                  >
                                    +
                                  </div>
                                  <div
                                    className="dynamicControl hide"
                                    id={`hideBtn${item.code}`}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      showResponsiveCols("hide", item);
                                    }}
                                  >
                                    -
                                  </div>
                                </td>
                                <td>
                                  <span className="mr-2">{index + 1}</span>
                                  <div className="btn-group">
                                    <button
                                      style={{
                                        padding: "2px 4px",
                                        lineHeight: "1.5",
                                      }}
                                      type="button"
                                      className="btn btn-outline-secondary dropdown-toggle"
                                      data-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <span className="sr-only">
                                        Toggle Dropdown
                                      </span>
                                    </button>
                                    <div className="dropdown-menu" role="menu">
                                      <Link
                                        className="dropdown-item"
                                        to={{
                                          pathname: "/supermaster/update",
                                        }}
                                        state={item}
                                      >
                                        Edit
                                      </Link>
                                      <Link
                                        className="dropdown-item"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          selectedUsers.push(item.id);

                                          item.status
                                            ? superAdminAllDeactive()
                                            : superAdminAllActive();
                                        }}
                                      >
                                        {item.status ? "Inactive" : "Activate"}
                                      </Link>
                                      <Link
                                        className="dropdown-item"
                                        to={{
                                          pathname:
                                            "/supermaster/child-limitUpdate",
                                        }}
                                        state={item}
                                      >
                                        Sub Admin Limit Update
                                      </Link>
                                    </div>
                                  </div>
                                </td>

                                <td
                                  className="mobile-d-10"
                                  style={{ textAlign: "start" }}
                                >
                                  {item.code}
                                </td>
                                <td
                                  className="mobile-d-9"
                                  style={{ textAlign: "start" }}
                                >
                                  {item.name}
                                </td>
                                <td
                                  className="mobile-d-7"
                                  style={{ textAlign: "start" }}
                                >
                                  {item.password}
                                </td>
                                <td
                                  className="mobile-d-6"
                                  style={{ textAlign: "start" }}
                                >
                                  {item.mobile}
                                </td>
                                <td
                                  className="mobile-d-5"
                                  style={{ textAlign: "start" }}
                                >
                                  {item.share}
                                </td>
                                <td
                                  className="mobile-d-4"
                                  style={{ textAlign: "start" }}
                                >
                                  {item.match_commission}
                                </td>
                                <td
                                  className="mobile-d-3"
                                  style={{ textAlign: "start" }}
                                >
                                  {item.session_commission}
                                </td>
                                <td
                                  className="mobile-d-2"
                                  style={{ textAlign: "start" }}
                                >
                                  {Math.round(item.limit)}
                                </td>
                                <td
                                  className="mobile-d-1"
                                  style={{ textAlign: "start" }}
                                >
                                  {item.status ? (
                                    <button
                                      className="btn btn-sm btn-success"
                                      id="active-1"
                                      style={{
                                        cursor: "default",
                                        pointerEvents: "none",
                                      }}
                                    >
                                      Active
                                    </button>
                                  ) : (
                                    <button
                                      className="btn btn-sm btn-danger"
                                      id="active-1"
                                      style={{
                                        cursor: "default",
                                        pointerEvents: "none",
                                      }}
                                    >
                                      Inactive
                                    </button>
                                  )}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <>
                            <tr>
                              <td colSpan={12} className="text-center p-5">
                                <h6 className="p-5">No records to display</h6>
                              </td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-5">
                    <div className="dataTables_info" id="example1_info">
                      Showing {indexOfFirstItem + 1} to {indexOfLastItem} of{" "}
                      {completeData.length} entries
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-7">
                    <div
                      className="dataTables_paginate paging_simple_numbers"
                      id="example1_paginate"
                    >
                      <ul className="pagination">
                        <li
                          className={`paginate_button page-item previous ${
                            currentPage <= 1 ? "disabled" : ""
                          }`}
                          id="example1_previous"
                        >
                          <a
                            tabIndex="0"
                            className="page-link"
                            onClick={handlePrevious}
                          >
                            Previous
                          </a>
                        </li>
                        {[...Array(Math.ceil(data.length / itemsPerPage))].map(
                          (_, index) => {
                            const pageNumber = index + 1;
                            if (
                              pageNumber >= currentPage - 2 &&
                              pageNumber <= currentPage + 2
                            ) {
                              return (
                                <li key={index} className="page-item">
                                  <a
                                    onClick={() => paginate(pageNumber)}
                                    className={`page-link ${
                                      currentPage === pageNumber
                                        ? "active-page-link"
                                        : ""
                                    }`}
                                  >
                                    {pageNumber}
                                  </a>
                                </li>
                              );
                            }
                            return null;
                          }
                        )}

                        <li
                          className={`paginate_button page-item next ${
                            currentPage >= Math.ceil(data.length / itemsPerPage)
                              ? "disabled"
                              : ""
                          }`}
                          id="example1_next"
                        >
                          <a
                            tabIndex="0"
                            className="page-link"
                            onClick={handleNext}
                          >
                            Next
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
