import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
// import { toast } from "react-toastify";
import { toast } from "react-hot-toast";

import { passwordGenerator } from "../../utils/passwordGenerator";
import useGetAccount from "../../hooks/useGetAccount";
import useUpdateAccount from "../../hooks/useUpdateAccount";
import useGetAccountStatus from "../../hooks/useGetAccountStatus";

export default function UpdateAgent() {
  const navigate = useNavigate();
  const accountType = localStorage.getItem("account");
  const uuid = localStorage.getItem("UUID");

  const userPassword = localStorage.getItem("password");
  const location = useLocation();
  const userData = location.state;
  const { mutate: getAccount } = useGetAccount();
  const { mutate: accountStatus } = useGetAccountStatus();
  const { mutate: updateAccount } = useUpdateAccount();
  const [parentData, setParentData] = useState([]);

  const [agentName, setAgentName] = useState("");
  const [agentNumber, setAgentNumber] = useState("");
  const [agentPassword, setAgentPassword] = useState("");
  const [agentReference, setAgentReference] = useState("");
  const [agentLimit, setAgentLimit] = useState(0);
  const [agentShare, setAgentShare] = useState(0);
  const [agentCasinoShare, setAgentCasinoShare] = useState(0);
  const [agentMobileShare, setAgentMobileShare] = useState(0);
  const [agentCommission, setAgentCommission] = useState("");
  const [agentMatchCommission, setAgentMatchCommission] = useState(0);
  const [agentSessionCommission, setAgentSessionCommission] = useState(0);
  const [agentCassinoCommission, setAgentCassinoCommission] = useState(0);

  useEffect(() => {
    setAgentName(userData.name);
    setAgentNumber(userData.mobile);
    setAgentPassword(userData.password);
    setAgentReference(userData.reference);
    setAgentLimit(userData.limit);
    setAgentShare(userData.share);
    setAgentCasinoShare(userData.cassino_share);
    setAgentMobileShare(userData.mobile_share);
    setAgentCommission(userData.commission);
    setAgentMatchCommission(userData.match_commission);
    setAgentSessionCommission(userData.session_commission);
    setAgentCassinoCommission(userData.cassino_commission);

    const payload = {
      acc_type: "super_agent",
      user_id: userData.parent,
    };

    getAccount(payload, {
      onSuccess: (response) => {
        setParentData(response.data.data);
      },
      onError: (error) => {
        console.log(error);
      },
    });
  }, [userData]);

  const generateNewPassword = () => {
    setAgentPassword(passwordGenerator());
  };

  function handleSubmit(e) {
    e.preventDefault();

    const userId = userData.id;

    const payload = {
      acc_type: "agent",
      id: userId,
      code: userData.code,
      reference: agentReference,
      name: agentName,
      mobile: agentNumber,
      password: String(agentPassword),
      parent: userData.parent,
      share: agentShare,
      cassino_share: agentCasinoShare,
      mobile_share: agentMobileShare,
      match_commission: agentMatchCommission,
      commission: agentCommission,
      session_commission: agentSessionCommission,
      cassino_commission: agentCassinoCommission,
    };

    updateAccount(
      { userId, payload },
      {
        onSuccess: () => {
          toast.success("Agent Updated Successfully");
          navigate("/agent");
        },
        onError: (error) => {
          console.log(error);
          toast.error("Agent Update Failed");
        },
      }
    );
  }

  function handleCancel(e) {
    e.preventDefault();
    navigate("/agent");
  }

  useEffect(() => {
    const payload = {
      user_id: uuid,
      acc_type: accountType,
    };

    accountStatus(payload, {
      onSuccess: (response) => {
        let userData = response.data.data;

        if (
          userData.status === false ||
          userData.status === "false" ||
          userData.status === "0" ||
          userData.status === 0
        ) {
          localStorage.clear();
          sessionStorage.clear();
        } else if (userData.password !== userPassword) {
          localStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Edit Agent </h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/agent">Agent</Link>
                </li>
                <li className="breadcrumb-item active">
                  <a>Edit Agent</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <form onSubmit={handleSubmit}>
            <div className="row mt-4 mb-4">
              {/* General Form */}
              <div className="col-md-6">
                <div class="accordion" id="accordionExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button"
                        id="create-button-1"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        General
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <div className="form-group mb-2">
                          <label className="form-label">Name</label>
                          <input
                            type="text"
                            id="agentName"
                            name="agentName"
                            className="form-control"
                            placeholder="Enter Name"
                            required
                            value={agentName}
                            onChange={(e) => {
                              setAgentName(e.target.value);
                            }}
                          />
                        </div>
                        <div className="form-group mb-2">
                          <label className="form-label" htmlFor="agentNumber">
                            Contact Number
                          </label>
                          <input
                            type="text"
                            id="agentNumber"
                            name="agentNumber"
                            className="form-control"
                            placeholder="Enter Mobile Number"
                            minLength={10}
                            maxLength={10}
                            required
                            value={agentNumber}
                            onChange={(e) => {
                              setAgentNumber(e.target.value);
                            }}
                          />
                        </div>
                        <div className="form-group mb-2">
                          <label className="form-label">Reference</label>
                          <input
                            type="text"
                            id="agentReference"
                            name="agentReference"
                            className="form-control"
                            placeholder="Enter Reference"
                            required
                            value={agentReference}
                            onChange={(e) => {
                              setAgentReference(e.target.value);
                            }}
                          />
                        </div>
                        <div className="form-group mb-2">
                          <label className="form-label" htmlFor="agentPassword">
                            Password
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              id="agentPassword"
                              name="agentPassword"
                              className="form-control"
                              required
                              value={agentPassword}
                              onChange={(e) => {
                                setAgentPassword(e.target.value);
                              }}
                            />
                            <span
                              class="input-group-text btn btn-primary"
                              onClick={generateNewPassword}
                            >
                              Generate Password!
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Share and Commission Form */}
              <div className="col-md-6">
                <div class="accordion" id="accordionExample2">
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button"
                        id="create-button-2"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="true"
                        aria-controls="collapseTwo"
                      >
                        Share and Commission
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      class="accordion-collapse collapse show"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample2"
                    >
                      <div class="accordion-body">
                        <div className="row">
                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">Agent Limit</label>
                            <input
                              type="number"
                              id="agentLimit"
                              name="agentLimit"
                              className="form-control"
                              value={agentLimit}
                              disabled
                            />
                          </div>
                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">My Limit</label>
                            <input
                              type="number"
                              className="form-control"
                              value={parentData?.limit}
                              disabled
                            />
                          </div>

                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">Agent Share</label>
                            <input
                              type="number"
                              id="agentShare"
                              name="agentShare"
                              className="form-control"
                              placeholder="0.00"
                              min="0"
                              max={parentData?.share}
                              step="0.01"
                              required
                              value={agentShare}
                              onChange={(e) => {
                                setAgentShare(e.target.value);
                              }}
                            />
                          </div>
                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">My Share</label>
                            <input
                              type="number"
                              className="form-control"
                              value={parentData?.share}
                              disabled
                            />
                          </div>

                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              Agent Casino Share
                            </label>
                            <input
                              type="number"
                              id="agentCasinoShare"
                              name="agentCasinoShare"
                              className="form-control"
                              placeholder="0.00"
                              min="0"
                              max={parentData?.cassino_share}
                              step="0.01"
                              required
                              value={agentCasinoShare}
                              onChange={(e) => {
                                setAgentCasinoShare(e.target.value);
                              }}
                            />
                          </div>
                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              My Casino Share
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              value={parentData?.cassino_share}
                              disabled
                            />
                          </div>

                          <div className="form-group mb-2 col-md-12">
                            <label className="form-label">Casino Status</label>
                            <select className="form-control">
                              <option value="off">OFF</option>
                              <option value="on">ON</option>
                            </select>
                          </div>

                          <div className="form-group mb-2 col-md-12">
                            <label className="form-label">
                              Commission Type
                            </label>
                            <select
                              className="form-control"
                              id="agentCommission"
                              name="agentCommission"
                              required
                              value={agentCommission}
                              onChange={(e) => {
                                setAgentCommission(e.target.value);
                              }}
                            >
                              <option value="commission_2be03622ef6c">
                                Bet By Bet
                              </option>
                              <option value="commission_6e444f0e316c">
                                No Com
                              </option>
                            </select>
                          </div>

                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              Agent Match Commission
                            </label>
                            <input
                              type="number"
                              id="agentMatchCommission"
                              name="agentMatchCommission"
                              className="form-control"
                              placeholder="0.00"
                              min="0"
                              max={parentData?.match_commission}
                              required
                              value={agentMatchCommission}
                              onChange={(e) => {
                                setAgentMatchCommission(e.target.value);
                              }}
                            />
                          </div>
                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              My Match Commission
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              value={parentData?.match_commission}
                              disabled
                            />
                          </div>

                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              Agent Session Commission
                            </label>
                            <input
                              type="number"
                              id="agentSessionCommission"
                              name="agentSessionCommission"
                              className="form-control"
                              placeholder="0.00"
                              min="0"
                              max={parentData?.session_commission}
                              required
                              value={agentSessionCommission}
                              onChange={(e) => {
                                setAgentSessionCommission(e.target.value);
                              }}
                            />
                          </div>
                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              My Session Commission
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              value={parentData?.session_commission}
                              disabled
                            />
                          </div>

                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              Agent Cassino Commission
                            </label>
                            <input
                              type="number"
                              id="agentCassinoCommission"
                              name="agentCassinoCommission"
                              className="form-control"
                              placeholder="0.00"
                              min="0"
                              max={parentData?.cassino_commission}
                              required
                              value={agentCassinoCommission}
                              onChange={(e) => {
                                setAgentCassinoCommission(e.target.value);
                              }}
                            />
                          </div>
                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              My Cassino Commission
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              value={parentData?.cassino_commission}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Action Buttons */}
              <div className="d-flex w-100 mt-2 col-12 justify-content-between">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-success">
                  Edit Agent
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
}
