/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
// import { toast } from "react-toastify";
import { toast } from "react-hot-toast";
import { passwordGenerator } from "../../utils/passwordGenerator";
import useGetAccount from "../../hooks/useGetAccount";
import useUpdateAccount from "../../hooks/useUpdateAccount";
import useGetAccountStatus from "../../hooks/useGetAccountStatus";

export default function UpdateSubadmin() {
  const accountType = localStorage.getItem("account");
  const uuid = localStorage.getItem("UUID");
  const userPassword = localStorage.getItem("password");
  const { mutate: accountStatus } = useGetAccountStatus();
  const navigate = useNavigate();
  const location = useLocation();
  const userData = location.state;
  const { mutate: getAccount } = useGetAccount();
  const { mutate: updateAccount } = useUpdateAccount();
  const [parentData, setParentData] = useState([]);

  const [subAdminName, setSubAdminName] = useState("");
  const [subAdminNumber, setSubAdminNumber] = useState("");
  const [subAdminPassword, setSubAdminPassword] = useState("");
  const [subAdminReference, setSubAdminReference] = useState("");
  const [subAdminLimit, setSubAdminLimit] = useState(0);
  const [subAdminShare, setSubAdminShare] = useState(0);
  const [subAdminCasinoShare, setSubAdminCasinoShare] = useState(0);
  const [subAdminMobileShare, setSubAdminMobileShare] = useState(0);
  const [subAdminCommission, setSubAdminCommission] = useState("");
  const [subAdminMatchCommission, setSubAdminMatchCommission] = useState(0);
  const [subAdminSessionCommission, setSubAdminSessionCommission] = useState(0);
  const [subAdminCassinoCommission, setSubAdminCassinoCommission] = useState(0);

  useEffect(() => {
    const payload = {
      user_id: uuid,
      acc_type: accountType,
    };

    accountStatus(payload, {
      onSuccess: (response) => {
        let userData = response.data.data;

        if (
          userData.status === false ||
          userData.status === "false" ||
          userData.status === "0" ||
          userData.status === 0
        ) {
          localStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else if (userData.password !== userPassword) {
          localStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSubAdminName(userData.name);
    setSubAdminNumber(userData.mobile);
    setSubAdminPassword(userData.password);
    setSubAdminReference(userData.reference);
    setSubAdminLimit(userData.limit);
    setSubAdminShare(userData.share);
    setSubAdminCasinoShare(userData.cassino_share);
    setSubAdminMobileShare(userData.mobile_share);
    setSubAdminCommission(userData.commission);
    setSubAdminMatchCommission(userData.match_commission);
    setSubAdminSessionCommission(userData.session_commission);
    setSubAdminCassinoCommission(userData.cassino_commission);

    const payload = {
      acc_type: "super_admin",
      user_id: userData.parent,
    };

    getAccount(payload, {
      onSuccess: (response) => {
        setParentData(response.data.data);
      },
      onError: (error) => {
        console.log(error);
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const generateNewPassword = () => {
    setSubAdminPassword(passwordGenerator());
  };

  function handleSubmit(e) {
    e.preventDefault();

    const userId = userData.id;

    const payload = {
      acc_type: "sub_admin",
      id: userId,
      code: userData.code,
      reference: subAdminReference,
      name: subAdminName,
      mobile: subAdminNumber,
      password: String(subAdminPassword),
      parent: userData.parent,
      share: subAdminShare,
      cassino_share: subAdminCasinoShare,
      mobile_share: subAdminMobileShare,
      match_commission: subAdminMatchCommission,
      commission: subAdminCommission,
      session_commission: subAdminSessionCommission,
      cassino_commission: subAdminCassinoCommission,
    };

    updateAccount(
      { userId, payload },
      {
        onSuccess: () => {
          toast.success("Sub Admin Updated Successfully");
          navigate("/sub-admin");
        },
        onError: (error) => {
          console.log(error);
          toast.error("Sub Admin Update Failed");
        },
      }
    );
  }

  function handleCancel(e) {
    e.preventDefault();
    navigate("/sub-admin");
  }

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Edit Sub Admin </h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/sub-admin">Sub Admin</Link>
                </li>
                <li className="breadcrumb-item active">
                  <a>Edit Sub Admin</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <form onSubmit={handleSubmit}>
            <div className="row mt-4 mb-4">
              {/* General Form */}
              <div className="col-md-6">
                <div class="accordion" id="accordionExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button"
                        id="create-button-1"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        General
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <div className="form-group mb-2">
                          <label className="form-label">Name</label>
                          <input
                            type="text"
                            id="subAdminName"
                            name="subAdminName"
                            className="form-control"
                            placeholder="Enter Name"
                            required
                            value={subAdminName}
                            onChange={(e) => {
                              setSubAdminName(e.target.value);
                            }}
                          />
                        </div>
                        <div className="form-group mb-2">
                          <label
                            className="form-label"
                            htmlFor="subAdminNumber"
                          >
                            Contact Number
                          </label>
                          <input
                            type="text"
                            id="subAdminNumber"
                            name="subAdminNumber"
                            className="form-control"
                            placeholder="Enter Mobile Number"
                            minLength={10}
                            maxLength={10}
                            required
                            value={subAdminNumber}
                            onChange={(e) => {
                              setSubAdminNumber(e.target.value);
                            }}
                          />
                        </div>
                        <div className="form-group mb-2">
                          <label className="form-label">Reference</label>
                          <input
                            type="text"
                            id="subAdminReference"
                            name="subAdminReference"
                            className="form-control"
                            placeholder="Enter Reference"
                            required
                            value={subAdminReference}
                            onChange={(e) => {
                              setSubAdminReference(e.target.value);
                            }}
                          />
                        </div>
                        <div className="form-group mb-2">
                          <label
                            className="form-label"
                            htmlFor="subAdminPassword"
                          >
                            Password
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              id="subAdminPassword"
                              name="subAdminPassword"
                              className="form-control"
                              required
                              value={subAdminPassword}
                              onChange={(e) => {
                                setSubAdminPassword(e.target.value);
                              }}
                            />
                            <span
                              class="input-group-text btn btn-primary"
                              onClick={generateNewPassword}
                            >
                              Generate Password!
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Share and Commission Form */}
              <div className="col-md-6">
                <div class="accordion" id="accordionExample2">
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button"
                        id="create-button-2"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="true"
                        aria-controls="collapseTwo"
                      >
                        Share and Commission
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      class="accordion-collapse collapse show"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample2"
                    >
                      <div class="accordion-body">
                        <div className="row">
                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              Sub Admin Limit
                            </label>
                            <input
                              type="number"
                              id="subAdminLimit"
                              name="subAdminLimit"
                              className="form-control"
                              value={subAdminLimit}
                              disabled
                            />
                          </div>
                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">My Limit</label>
                            <input
                              type="number"
                              className="form-control"
                              value={parentData?.limit}
                              disabled
                            />
                          </div>

                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              Sub Admin Share
                            </label>
                            <input
                              type="number"
                              id="subAdminShare"
                              name="subAdminShare"
                              className="form-control"
                              placeholder="0.00"
                              min="0"
                              max={parentData?.share}
                              step="0.01"
                              required
                              value={subAdminShare}
                              onChange={(e) => {
                                setSubAdminShare(e.target.value);
                              }}
                            />
                          </div>
                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">My Share</label>
                            <input
                              type="number"
                              className="form-control"
                              value={parentData?.share}
                              disabled
                            />
                          </div>

                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              Sub Admin Casino Share
                            </label>
                            <input
                              type="number"
                              id="subAdminCasinoShare"
                              name="subAdminCasinoShare"
                              className="form-control"
                              placeholder="0.00"
                              min="0"
                              max={parentData?.cassino_share}
                              step="0.01"
                              required
                              value={subAdminCasinoShare}
                              onChange={(e) => {
                                setSubAdminCasinoShare(e.target.value);
                              }}
                            />
                          </div>
                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              My Casino Share
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              value={parentData?.cassino_share}
                              disabled
                            />
                          </div>

                          <div className="form-group mb-2 col-md-12">
                            <label className="form-label">Casino Status</label>
                            <select className="form-control">
                              <option value="off">OFF</option>
                              <option value="on">ON</option>
                            </select>
                          </div>

                          <div className="form-group mb-2 col-md-12">
                            <label className="form-label">
                              Commission Type
                            </label>
                            <select
                              className="form-control"
                              id="subAdminCommission"
                              name="subAdminCommission"
                              required
                              value={subAdminCommission}
                              onChange={(e) => {
                                setSubAdminCommission(e.target.value);
                              }}
                            >
                              <option value="commission_2be03622ef6c">
                                Bet By Bet
                              </option>
                              <option value="commission_6e444f0e316c">
                                No Com
                              </option>
                            </select>
                          </div>

                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              Sub Admin Match Commission
                            </label>
                            <input
                              type="number"
                              id="subAdminMatchCommission"
                              name="subAdminMatchCommission"
                              className="form-control"
                              placeholder="0.00"
                              min="0"
                              max={parentData?.match_commission}
                              required
                              value={subAdminMatchCommission}
                              onChange={(e) => {
                                setSubAdminMatchCommission(e.target.value);
                              }}
                            />
                          </div>
                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              My Match Commission
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              value={parentData?.match_commission}
                              disabled
                            />
                          </div>

                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              Sub Admin Session Commission
                            </label>
                            <input
                              type="number"
                              id="subAdminSessionCommission"
                              name="subAdminSessionCommission"
                              className="form-control"
                              placeholder="0.00"
                              min="0"
                              max={parentData?.session_commission}
                              required
                              value={subAdminSessionCommission}
                              onChange={(e) => {
                                setSubAdminSessionCommission(e.target.value);
                              }}
                            />
                          </div>
                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              My Session Commission
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              value={parentData?.session_commission}
                              disabled
                            />
                          </div>

                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              Sub Admin Cassino Commission
                            </label>
                            <input
                              type="number"
                              id="subAdminCassinoCommission"
                              name="subAdminCassinoCommission"
                              className="form-control"
                              placeholder="0.00"
                              min="0"
                              max={parentData?.cassino_commission}
                              required
                              value={subAdminCassinoCommission}
                              onChange={(e) => {
                                setSubAdminCassinoCommission(e.target.value);
                              }}
                            />
                          </div>
                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              My Cassino Commission
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              value={parentData?.cassino_commission}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Action Buttons */}
              <div className="d-flex w-100 mt-2 col-12 justify-content-between">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-success">
                  Edit Sub Admin
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
}
