/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import "./InplayControlGame.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-hot-toast";
import { match } from "../../cms/gameData";
import useGetMatchSetting from "../../hooks/Games/Inplay/useGetMatchSetting";
import useGetSessionSetting from "../../hooks/Games/Inplay/useGetSessionSetting";
// import useGetGameDetailsPaid from "../../hooks/Games/Inplay/useGetGameDetailsPaid";
import useSaveSessionSetting from "../../hooks/Games/Inplay/useSaveSessionSetting";
import useUpdateMatchSetting from "../../hooks/Games/Inplay/useUpdateMatchSetting";
import useGetAccountStatus from "../../hooks/useGetAccountStatus";
import useSocketIceexch from "../../hooks/Sockets/useSocketIceexch";
import useSocketBetguru from "../../hooks/Sockets/useSocketBetguru";
import useSocketPersonal from "../../hooks/Sockets/useSocketPersonal";

export default function InplayControlGame() {
  // const accountType = localStorage.getItem("account");
  const uuid = localStorage.getItem("UUID");
  const { mutate: accountStatus } = useGetAccountStatus();
  const navigate = useNavigate();
  const location = useLocation();
  let gameData = [];
  let gameId = "";
  if (location.state) {
    gameData = location.state;
    gameId = location.state?.id;
  }
  let accountType = localStorage.getItem("account");

  const userPassword = localStorage.getItem("password");

  const { mutate: getMatchSetting } = useGetMatchSetting();
  const { mutate: getSessionSetting } = useGetSessionSetting();
  // const { mutate: getGameDetailsPaid } = useGetGameDetailsPaid();
  const { mutate: saveSessionSetting } = useSaveSessionSetting();
  const { mutate: updateMatchSetting } = useUpdateMatchSetting();

  const [panelMessage, setPanelMessage] = useState("");
  const [betTimer, setBetTimer] = useState(0);
  const [rateMeter, setRateMeter] = useState(0);
  const [sessionMin, setSessionMin] = useState(100);
  const [sessionMax, setSessionMax] = useState(100000);
  const [matchMin, setMatchMin] = useState(100);
  const [matchMax, setMatchMax] = useState(100000);
  const [matchBetLock, setMatchBetLock] = useState(false);

  const [gameSettingData, setGameSettingData] = useState([]);
  let sessionOfMatch = [];
  const [sessionData, setSessionData] = useState([]);

  const [matchId1, setMatchId1] = useState(0);
  const [matchId2, setMatchId2] = useState(0);
  const [matchId4, setMatchId4] = useState(0);

  const betguruData = useSocketBetguru(matchId1);
  const iceexchData = useSocketIceexch(matchId2);
  const personalapiData = useSocketPersonal(matchId4);

  const handleGetGameDetailsPaid = (gameCode) => {
    setMatchId2(gameCode);
  };

  const handleGetAppleExch = (gameCode) => {
    setMatchId1(gameCode);
  };

  const handleGetPersonal = (gameCode) => {
    setMatchId4(gameCode);
  };

  const resetPanel = () => {
    setPanelMessage("");
  };

  function handleSubmit(e) {
    e.preventDefault();

    const payload = {
      pannel_message: panelMessage,
      bet_timer: Number(betTimer),
      rate_meter: Number(rateMeter),
      session_min: Number(sessionMin),
      session_max: Number(sessionMax),
      match_min: Number(matchMin),
      match_max: Number(matchMax),
      match_bet_lock: matchBetLock,
    };

    updateMatchSetting(
      { gameId, payload },
      {
        onSuccess: () => {
          toast.success("Game Setting Updated Successfully");
          navigate("/inplay");
        },
        onError: (error) => {
          toast.error("Game Setting Updated Failed");
          console.log(error);
        },
      }
    );
  }

  function handleSessionSetting(socketData = {}) {
    getSessionSetting(gameId, {
      onSuccess: (data) => {
        if (data.data.data.length !== 0) {
          data.data.data.forEach((el) => {
            if (el.allow !== false) {
              sessionOfMatch.push({
                session: el.session_name,
                match_id: gameId,
                allow: el.allow,
                id: el.id,
              });
            }
          });

          if (gameData.code === 0 || gameData.code === "0") {
            match.data[2].markets.forEach((ele) => {
              if (
                !sessionOfMatch.some(
                  (elem) => elem.session === ele.marketType
                ) &&
                ele.runners !== undefined
              ) {
                sessionOfMatch.push({
                  session: ele.marketType,
                  match_id: gameId,
                  allow: false,
                  id: null,
                });
              }
            });
          } else {
            if (gameData.api === "1") {
              let fancyOddsIndex = socketData.length < 3 ? 1 : 2;

              if (gameData.match_type !== "Test") {
                if (socketData.length > 1) {
                  socketData[fancyOddsIndex].markets.forEach((ele) => {
                    if (
                      !sessionOfMatch.some(
                        (elem) => elem.session === ele.marketType
                      ) &&
                      ele.runners != undefined
                    ) {
                      sessionOfMatch.push({
                        session: ele.marketType,
                        match_id: gameId,
                        allow: false,
                        id: null,
                      });
                    }
                  });
                } else {
                  socketData[0].markets.forEach((ele) => {
                    if (
                      !sessionOfMatch.some(
                        (elem) => elem.session === ele.marketType
                      ) &&
                      ele.runners != undefined
                    ) {
                      sessionOfMatch.push({
                        session: ele.marketType,
                        match_id: gameId,
                        allow: false,
                        id: null,
                      });
                    }
                  });
                }
              } else {
                if (socketData.length > 0) {
                  socketData[0].markets.forEach((ele) => {
                    if (
                      !sessionOfMatch.some(
                        (elem) => elem.session === ele.marketType
                      ) &&
                      ele.runners != undefined
                    ) {
                      sessionOfMatch.push({
                        session: ele.marketType,
                        match_id: gameId,
                        allow: false,
                        id: null,
                      });
                    }
                  });
                }
              }
            } else if (gameData.api === "2") {
              let session = socketData.sessionOdds;

              if (session.length > 0) {
                if (session.length > 0) {
                  session.forEach((ele) => {
                    if (
                      !sessionOfMatch.some((elem) => elem.session == ele.name)
                    ) {
                      sessionOfMatch.push({
                        session: ele.name,
                        match_id: gameId,
                        allow: false,
                        id: null,
                      });
                    }
                  });
                }
              }
            } else if (gameData.api === "4") {
              let session = socketData.fancyOdds;

              if (session.length > 0) {
                for (let i = 0; i < session.length; i++) {
                  if (session[i].gtype === "Ball By Ball") {
                    continue;
                  } else {
                    // eslint-disable-next-line no-loop-func
                    session[i].oddDatas.forEach((ele) => {
                      if (
                        !sessionOfMatch.some(
                          (elem) => elem.session === ele.rname
                        )
                      ) {
                        sessionOfMatch.push({
                          session: ele.rname,
                          match_id: gameId,
                          allow: false,
                          id: null,
                        });
                      }
                    });
                  }
                }
              }
            }
          }
        } else {
          if (gameData.code === 0 || gameData.code === "0") {
            sessionOfMatch = [];

            match.data[2].markets.forEach((el) => {
              if (el.runners != undefined) {
                sessionOfMatch.push({
                  session: el.marketType,
                  match_id: gameId,
                  allow: false,
                  id: null,
                });
              }
            });
          } else {
            if (gameData.api === "1") {
              let fancyOddsIndex = socketData.length < 3 ? 1 : 2;

              if (gameData.match_type !== "Test") {
                if (socketData.length > 1) {
                  socketData[fancyOddsIndex].markets.forEach((ele) => {
                    if (
                      !sessionOfMatch.some(
                        (elem) => elem.session == ele.marketType
                      ) &&
                      ele.runners != undefined
                    ) {
                      sessionOfMatch.push({
                        session: ele.marketType,
                        match_id: gameId,
                        allow: false,
                        id: null,
                      });
                    }
                  });
                }
              } else {
                if (socketData.length > 0) {
                  socketData[0].markets.forEach((ele) => {
                    if (
                      !sessionOfMatch.some(
                        (elem) => elem.session == ele.marketType
                      ) &&
                      ele.runners != undefined
                    ) {
                      sessionOfMatch.push({
                        session: ele.marketType,
                        match_id: gameId,
                        allow: false,
                        id: null,
                      });
                    }
                  });
                }
              }
            } else if (gameData.api === "2") {
              let session = socketData.sessionOdds;

              if (session.length > 0) {
                if (session.length > 0) {
                  session.forEach((ele) => {
                    if (
                      !sessionOfMatch.some((elem) => elem.session == ele.name)
                    ) {
                      sessionOfMatch.push({
                        session: ele.name,
                        match_id: gameId,
                        allow: false,
                        id: null,
                      });
                    }
                  });
                }
              }
            } else if (gameData.api === "4") {
              let session = socketData.fancyOdds;

              if (session.length > 0) {
                for (let i = 0; i < session.length; i++) {
                  if (session[i].gtype === "Ball By Ball") {
                    continue;
                  } else {
                    // eslint-disable-next-line no-loop-func
                    session[i].oddDatas.forEach((ele) => {
                      if (
                        !sessionOfMatch.some(
                          (elem) => elem.session === ele.rname
                        )
                      ) {
                        sessionOfMatch.push({
                          session: ele.rname,
                          match_id: gameId,
                          allow: false,
                          id: null,
                        });
                      }
                    });
                  }
                }
              }
            }
          }
        }

        setSessionData(sessionOfMatch);
      },
      onError: (error) => {
        console.log(error);
      },
    });
  }

  const saveSession = (session, allow, id) => {
    let payload = {};

    if (id === null) {
      payload = {
        match_id: gameId,
        session_name: session,
        allow: allow,
      };
    } else {
      payload = {
        match_id: gameId,
        session_name: session,
        allow: allow,
        id: id,
      };
    }

    console.log(payload);

    saveSessionSetting(payload, {
      onSuccess: () => {
        toast.success("Session Updated Successfully");
        handleSessionSetting();
      },
      onError: (error) => {
        toast.error("Session Updated Failed");
        console.log(error);
      },
    });
  };

  useEffect(() => {
    const payload = {
      user_id: uuid,
      acc_type: accountType,
    };

    accountStatus(payload, {
      onSuccess: (response) => {
        let userData = response.data.data;

        if (
          userData.status === false ||
          userData.status === "false" ||
          userData.status === "0" ||
          userData.status === 0
        ) {
          localStorage.clear();
          sessionStorage.clear();
        } else if (userData.password !== userPassword) {
          localStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getMatchSetting(gameId, {
      onSuccess: (data) => {
        setGameSettingData(data.data.data);
      },
      onError: (error) => {
        console.log(error);
      },
    });

    if (gameData.api === "1") {
      if (gameData.code !== "0") {
        handleGetGameDetailsPaid(gameData.code);
      }
    } else if (gameData.api === "2") {
      if (gameData.code !== "0") {
        handleGetAppleExch(gameData.code);
      }
    } else if (gameData.api === "4") {
      if (gameData.code !== "0") {
        handleGetPersonal(gameData.code);
      }
    }

    handleSessionSetting();

    if (accountType === "admin" || accountType === "power_user") {
      console.log("ok");
    } else {
      navigate("/inplay");
      toast.error("You don't have access!");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (gameSettingData.length !== 0) {
      if (gameSettingData.rate_meter === null) {
        setRateMeter(0);
      } else {
        setRateMeter(gameSettingData.rate_meter);
      }

      setPanelMessage(gameSettingData.pannel_message);
      setBetTimer(gameSettingData.bet_timer);
      setSessionMin(gameSettingData.session_min);
      setSessionMax(gameSettingData.session_max);
      setMatchMin(gameSettingData.match_min);
      setMatchMax(gameSettingData.match_max);
      setMatchBetLock(gameSettingData.match_bet_lock);
    }
  }, [gameSettingData]);

  useEffect(() => {
    if (iceexchData) {
      handleSessionSetting(iceexchData.data.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iceexchData]);

  useEffect(() => {
    if (betguruData) {
      handleSessionSetting(betguruData.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [betguruData]);

  useEffect(() => {
    if (personalapiData) {
      handleSessionSetting(personalapiData.data.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personalapiData]);

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Control Game</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/inplay">In Play</Link>
                </li>
                <li className="breadcrumb-item active">
                  <a>Control Game</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="row mt-3 mb-4">
          {/* General Form */}
          <div className="col-md-6 w-100 mb-3">
            <div className="accordion" id="accordionExample2">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button"
                    id="create-button-1"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="true"
                    aria-controls="collapseTwo"
                  >
                    General
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse show"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample2"
                >
                  <div className="accordion-body">
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="form-group mb-2 mt-0 col-md-12">
                          <label className="form-label">Panel Message</label>
                          <div className="input-group">
                            <input
                              type="text"
                              id="panelMessage"
                              name="panelMessage"
                              className="form-control"
                              placeholder="Panel Message"
                              value={panelMessage}
                              onChange={(e) => {
                                setPanelMessage(e.target.value);
                              }}
                            />
                            <span
                              className="input-group-text btn btn-primary"
                              onClick={resetPanel}
                            >
                              Reset
                            </span>
                          </div>
                        </div>

                        <div className="form-group mb-2 col-md-12">
                          <label className="form-label">
                            Bet Timing (Second)
                          </label>
                          <input
                            type="number"
                            id="betTimer"
                            name="betTimer"
                            className="form-control"
                            required
                            value={betTimer}
                            onChange={(e) => {
                              setBetTimer(e.target.value);
                            }}
                          />
                        </div>

                        <div className="form-group mb-2 col-md-12">
                          <label className="form-label">Rate Different</label>
                          <input
                            type="number"
                            id="rateMeter"
                            name="rateMeter"
                            className="form-control"
                            required
                            value={rateMeter}
                            onChange={(e) => {
                              setRateMeter(e.target.value);
                            }}
                          />
                        </div>

                        <div className="form-group mb-2 col-md-6">
                          <label className="form-label">Session Min</label>
                          <input
                            type="number"
                            id="sessionMin"
                            name="sessionMin"
                            className="form-control"
                            value={sessionMin}
                            required
                            onChange={(e) => {
                              setSessionMin(e.target.value);
                            }}
                          />
                        </div>
                        <div className="form-group mb-2 col-md-6">
                          <label className="form-label">Session Max</label>
                          <input
                            type="number"
                            id="sessionMax"
                            name="sessionMax"
                            className="form-control"
                            value={sessionMax}
                            required
                            onChange={(e) => {
                              setSessionMax(e.target.value);
                            }}
                          />
                        </div>

                        <div className="form-group mb-2 col-md-6">
                          <label className="form-label">Match Min</label>
                          <input
                            type="number"
                            id="matchMin"
                            name="matchMin"
                            className="form-control"
                            value={matchMin}
                            required
                            onChange={(e) => {
                              setMatchMin(e.target.value);
                            }}
                          />
                        </div>
                        <div className="form-group mb-2 col-md-6">
                          <label className="form-label">Match Max</label>
                          <input
                            type="number"
                            id="matchMax"
                            name="matchMax"
                            className="form-control"
                            value={matchMax}
                            required
                            onChange={(e) => {
                              setMatchMax(e.target.value);
                            }}
                          />
                        </div>

                        <div className="form-group mb-2 col-md-12">
                          <label className="form-label">Lagai/Khai Lock</label>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={matchBetLock}
                              id="matchBetLock"
                              name="matchBetLock"
                              onChange={(e) => {
                                setMatchBetLock(e.target.checked);
                              }}
                              style={{ cursor: "pointer" }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="matchBetLock"
                            >
                              Yes / No
                            </label>
                          </div>
                        </div>

                        <button type="submit" className="btn btn-primary">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Sessions Form */}
          <div className="col-md-6 w-100">
            <div className="accordion" id="accordionExample3">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button"
                    id="create-button-1"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="true"
                    aria-controls="collapseThree"
                  >
                    Sessions
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse show"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample3"
                >
                  <div className="accordion-body">
                    <ul className="list-group">
                      {sessionData?.length !== 0 ? (
                        sessionData?.map((session, index) => (
                          <li
                            key={index}
                            className="list-group-item match-session-check"
                          >
                            {session.session}
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id={session.session}
                                name={session.session}
                                defaultChecked={session.allow}
                                onChange={() => {
                                  saveSession(
                                    session.session,
                                    !session.allow,
                                    session.id
                                  );
                                }}
                                style={{ cursor: "pointer" }}
                              />
                            </div>
                          </li>
                        ))
                      ) : (
                        <p>No Session Available</p>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Action Buttons */}
        </div>
      </section>
    </div>
  );
}
