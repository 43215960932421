/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import "./CreateNewAgent.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
// import { toast } from "react-toastify";
import { toast } from "react-hot-toast";

import { passwordGenerator } from "../../utils/passwordGenerator";
import useCreateAccount from "../../hooks/useCreateAccount";
import useUpdateLimit from "../../hooks/useUpdateLimit";
import useGetAccountStatus from "../../hooks/useGetAccountStatus";

export default function CreateNewAgent() {
  const navigate = useNavigate();
  const accountType = localStorage.getItem("account");
  const uuid = localStorage.getItem("UUID");

  const userPassword = localStorage.getItem("password");

  const location = useLocation();
  let parentData = [];
  if (location.state) {
    parentData = location.state;
  } else {
    parentData = JSON.parse(localStorage.getItem("DATA"));
  }
  const { mutate: createAccount } = useCreateAccount();
  const { mutate: updateLimit } = useUpdateLimit();
  const { mutate: accountStatus } = useGetAccountStatus();
  const [agentName, setAgentName] = useState("");
  const [agentNumber, setAgentNumber] = useState("");
  const [agentPassword, setAgentPassword] = useState("");
  const [agentReference, setAgentReference] = useState("");
  const [agentLimit, setAgentLimit] = useState(0);
  const [agentShare, setAgentShare] = useState(0);
  const [agentCasinoShare, setAgentCasinoShare] = useState(0);
  const [agentMobileShare, setAgentMobileShare] = useState(0);
  const [agentCommission, setAgentCommission] = useState(
    "commission_2be03622ef6c"
  );
  const [agentMatchCommission, setAgentMatchCommission] = useState(0);
  const [agentSessionCommission, setAgentSessionCommission] = useState(0);
  const [agentCassinoCommission, setAgentCassinoCommission] = useState(0);

  useEffect(() => {
    setAgentPassword(passwordGenerator());
  }, []);

  const generateNewPassword = () => {
    setAgentPassword(passwordGenerator());
  };

  function handleSubmit(e) {
    e.preventDefault();

    const payload = {
      acc_type: "agent",
      name: agentName,
      reference: agentReference,
      password: String(agentPassword),
      parent: parentData.id,
      parentCode: parentData.code,
      mobile: agentNumber,
      limit: agentLimit,
      share: agentShare,
      cassino_share: agentCasinoShare,
      mobile_share: agentMobileShare,
      commission: agentCommission,
      match_commission: agentMatchCommission,
      session_commission: agentSessionCommission,
      cassino_commission: agentCassinoCommission,
    };

    toast.promise(
      new Promise((resolve, reject) => {
        createAccount(payload, {
          onSuccess: (data) => {
            console.log(data);
            const agentLimitPayload = {
              user_id: data.data.data.id,
              acc_type: "agent",
              limit: agentLimit,
              coins: agentLimit,
              types: "credit",
              description: "Agent Limit Update",
            };
            const parentLimitPayload = {
              user_id: parentData.id,
              acc_type: "super_agent",
              limit: parentData.limit - agentLimit,
              coins: agentLimit,
              types: "debit",
              description: "Super Admin Limit Update",
            };
            updateLimit(parentLimitPayload, {
              onSuccess: () => {
                updateLimit(agentLimitPayload, {
                  onSuccess: () => {
                    resolve(); // This will trigger the success toast
                    navigate("/agent");
                  },
                  onError: (error) => {
                    console.error(error);
                    reject(error); // This will trigger the error toast
                  },
                });
              },
              onError: (error) => {
                console.error(error);
                reject(error); // This will trigger the error toast
              },
            });
          },
          onError: (error) => {
            console.error(error);
            reject(error); // This will trigger the error toast
          },
        });
      }),
      {
        loading: "Creating agent...",
        success: <b>Agent created successfully!</b>,
        error: <b>Something went wrong! Please try again later.</b>,
      }
    );
  }

  const [isOpen, setIsOpen] = useState(true);
  const [isFirstAccordionOpen, setIsFirstAccordionOpen] = useState(true);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const toggleFirstAccordion = () => {
    setIsFirstAccordionOpen(!isFirstAccordionOpen);
  };

  useEffect(() => {
    const payload = {
      user_id: uuid,
      acc_type: accountType,
    };

    accountStatus(payload, {
      onSuccess: (response) => {
        let userData = response.data.data;

        if (
          userData.status === false ||
          userData.status === "false" ||
          userData.status === "0" ||
          userData.status === 0
        ) {
          localStorage.clear();
          sessionStorage.clear();
        } else if (userData.password !== userPassword) {
          localStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Create New Agent </h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/agent">Agent</Link>
                </li>
                <li className="breadcrumb-item active">
                  <a>Create Agent</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <form onSubmit={handleSubmit}>
            <div className="row mt-4 mb-4">
              {/* General Form */}
              <div className="col-md-6">
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className={`accordion-button ${
                          isOpen ? "" : "collapsed"
                        }`}
                        id="create-button-1"
                        type="button"
                        onClick={toggleAccordion}
                        aria-expanded={isOpen}
                        aria-controls="collapseOne"
                      >
                        General
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className={`accordion-collapse collapse ${
                        isOpen ? "show" : ""
                      }`}
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <div className="form-group mb-2">
                          <label className="form-label" htmlFor="agentCode">
                            Code
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="agentCode"
                            name="agentCode"
                            value="Auto generated"
                            disabled
                          />
                        </div>
                        <div className="form-group mb-2">
                          <label className="form-label">Name</label>
                          <input
                            type="text"
                            id="agentName"
                            name="agentName"
                            className="form-control"
                            placeholder="Enter Name"
                            required
                            onChange={(e) => {
                              setAgentName(e.target.value);
                            }}
                          />
                        </div>
                        <div className="form-group mb-2">
                          <label className="form-label" htmlFor="agentNumber">
                            Contact Number
                          </label>
                          <input
                            type="text"
                            id="agentNumber"
                            name="agentNumber"
                            className="form-control"
                            placeholder="Enter Mobile Number"
                            minLength={0}
                            maxLength={10}
                            required
                            onChange={(e) => {
                              setAgentNumber(e.target.value);
                            }}
                          />
                        </div>
                        <div className="form-group mb-2">
                          <label className="form-label">Reference</label>
                          <input
                            type="text"
                            id="agentReference"
                            name="agentReference"
                            className="form-control"
                            placeholder="Enter Reference"
                            required
                            onChange={(e) => {
                              setAgentReference(e.target.value);
                            }}
                          />
                        </div>
                        <div className="form-group mb-2">
                          <label className="form-label" htmlFor="agentPassword">
                            Password
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              id="agentPassword"
                              name="agentPassword"
                              className="form-control"
                              required
                              value={agentPassword}
                              onChange={(e) => {
                                setAgentPassword(e.target.value);
                              }}
                            />
                            <span
                              className="input-group-text btn btn-primary"
                              onClick={generateNewPassword}
                            >
                              Generate Password!
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Share and Commission Form */}
              <div className="col-md-6">
                <div className="accordion" id="accordionExample2">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className={`accordion-button ${
                          isFirstAccordionOpen ? "" : "collapsed"
                        }`}
                        id="create-button-2"
                        type="button"
                        onClick={toggleFirstAccordion}
                        aria-expanded={isFirstAccordionOpen}
                        aria-controls="collapseTwo"
                      >
                        Share and Commission
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className={`accordion-collapse collapse ${
                        isFirstAccordionOpen ? "show" : ""
                      }`}
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample2"
                    >
                      <div class="accordion-body">
                        <div className="row">
                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">Agent Limit</label>
                            <input
                              type="number"
                              id="agentLimit"
                              name="agentLimit"
                              className="form-control"
                              placeholder="0.00"
                              min="0"
                              max={parentData.limit}
                              required
                              onChange={(e) => {
                                setAgentLimit(e.target.value);
                              }}
                            />
                          </div>
                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">My Limit</label>
                            <input
                              type="number"
                              className="form-control"
                              value={parentData.limit}
                              disabled
                            />
                          </div>

                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">Agent Share</label>
                            <input
                              type="number"
                              id="agentShare"
                              name="agentShare"
                              className="form-control"
                              placeholder="0.00"
                              min="0"
                              max={parentData.share}
                              step="0.01"
                              required
                              onChange={(e) => {
                                setAgentShare(e.target.value);
                              }}
                            />
                          </div>
                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">My Share</label>
                            <input
                              type="number"
                              className="form-control"
                              value={parentData.share}
                              disabled
                            />
                          </div>

                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              Agent Casino Share
                            </label>
                            <input
                              type="number"
                              id="agentCasinoShare"
                              name="agentCasinoShare"
                              className="form-control"
                              placeholder="0.00"
                              min="0"
                              max={parentData.cassino_share}
                              step="0.01"
                              required
                              onChange={(e) => {
                                setAgentCasinoShare(e.target.value);
                              }}
                            />
                          </div>
                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              My Casino Share
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              value={parentData.cassino_share}
                              disabled
                            />
                          </div>

                          <div className="form-group mb-2 col-md-12">
                            <label className="form-label">Casino Status</label>
                            <select className="form-control">
                              <option value="off">OFF</option>
                              <option value="on">ON</option>
                            </select>
                          </div>

                          <div className="form-group mb-2 col-md-12">
                            <label className="form-label">
                              Commission Type
                            </label>
                            <select
                              className="form-control"
                              id="agentCommission"
                              name="agentCommission"
                              required
                              onChange={(e) => {
                                const value = e.target.value;
                                setAgentCommission(value);

                                // Automatically set match, session, and cassino commissions to 0 if 'No Com' is selected
                                if (value === "commission_6e444f0e316c") {
                                  setAgentMatchCommission(0);
                                  setAgentSessionCommission(0);
                                  setAgentCassinoCommission(0); // Handle cassino commission
                                }
                              }}
                            >
                              <option value="commission_2be03622ef6c">
                                Bet By Bet
                              </option>
                              <option value="commission_6e444f0e316c">
                                No Com
                              </option>
                            </select>
                          </div>

                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              Agent Match Commission
                            </label>
                            <input
                              type="number"
                              id="agentMatchCommission"
                              name="agentMatchCommission"
                              className="form-control"
                              placeholder="0.00"
                              min="0"
                              max={parentData.match_commission}
                              value={agentMatchCommission} // Bind value to state
                              required
                              onChange={(e) =>
                                setAgentMatchCommission(e.target.value)
                              }
                              disabled={
                                agentCommission === "commission_6e444f0e316c"
                              } // Disable if 'No Com' selected
                            />
                          </div>

                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              My Match Commission
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              value={parentData.match_commission}
                              disabled
                            />
                          </div>

                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              Agent Session Commission
                            </label>
                            <input
                              type="number"
                              id="agentSessionCommission"
                              name="agentSessionCommission"
                              className="form-control"
                              placeholder="0.00"
                              min="0"
                              max={parentData.session_commission}
                              value={agentSessionCommission} // Bind value to state
                              required
                              onChange={(e) =>
                                setAgentSessionCommission(e.target.value)
                              }
                              disabled={
                                agentCommission === "commission_6e444f0e316c"
                              } // Disable if 'No Com' selected
                            />
                          </div>

                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              My Session Commission
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              value={parentData.session_commission}
                              disabled
                            />
                          </div>

                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              Agent Cassino Commission
                            </label>
                            <input
                              type="number"
                              id="agentCassinoCommission"
                              name="agentCassinoCommission"
                              className="form-control"
                              placeholder="0.00"
                              min="0"
                              max={parentData.cassino_commission}
                              value={agentCassinoCommission} // Bind value to state
                              required
                              onChange={(e) =>
                                setAgentCassinoCommission(e.target.value)
                              }
                              disabled={
                                agentCommission === "commission_6e444f0e316c"
                              } // Disable if 'No Com' selected
                            />
                          </div>

                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              My Cassino Commission
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              value={parentData.cassino_commission}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Action Buttons */}
              <div className="d-flex w-100 mt-2 col-12 justify-content-between">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/agent");
                  }}
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-success">
                  Create New Agent
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
}
